@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --primary-color: #232327;
  --secondary-color: #727378;
  --black-color: #000;
  --white-color: #fff;
  --fbm-color: #8a6d3b;
  --dark-grey: #474b64;
  --mid-grey: #646464;
  --link-color: #337ab7;
  --header-bg: #f8f8f8;
  --fbm-bg-color: #fcf8e3;
  --border-color: #ccc;
  --items-bg: #e7e7e7;
  --base-color: #00c853;
  --desc-color: #333;
  --bs-accordion-btn-icon-width: 14px !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: "Inter", serif !important; */
}

body {
  padding: 0;
  background-color: #ffff;
  margin: 0;
  width: 100%;
  height: auto;
  overflow: auto;
  color: #000000 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: "Inter", serif !important;
  line-height: 1;
  scroll-behavior: smooth;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  display: inline-block;
  text-decoration: none !important;
  cursor: pointer;
}

ul {
  margin-bottom: 0 !important;
}

.input-group input {
  height: 25px !important;
  border-radius: 0px 3px 3px 0px !important;
  color: #555 !important;
  text-align: right !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 22.86px;
}

.form-group input {
  height: 20px !important;
  padding: 0px 15px !important;
  font-size: 13px !important;
  color: var(--bs-body-color) !important;
}

.accordion-body #container .highcharts-container {
  margin-top: -6px !important;
}

.content-container {
  padding-top: 80px !important;
}

/* Header */
.sellers-header {
  background-color: var(--header-bg);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  width: 100%;
  z-index: 1111;
}

.seller-search {
  line-height: 1.42857143;
  color: var(--mid-grey);
  background-color: var(--white-color);
  background-image: none;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 6px 4px;
  font-size: 12px;
}

.form-control:focus {
  outline: none !important;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  box-shadow: none !important;
}

.list-item:hover {
  background-color: var(--items-bg);
}

.list-item {
  transition: all 0.3s ease;
}

.list-item a {
  color: var(--link-color);
  padding: 10px 15px;
  font-size: 12px;
}

.sellers-icon {
  font-size: 16px;
}

#dropdown-basic-button {
  display: flex;
  align-items: center;
  gap: 5px;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: var(--desc-color) !important;
  color: #fff;
  border: 1px solid var(--border-color);
}

nav .btn-primary {
  background-color: transparent;
  border: 1px solid transparent !important;
  color: var(--link-color);
}

nav .btn-primary:hover {
  background-color: var(--items-bg);
  color: var(--link-color);
}

.product-details .seller-description {
  color: #000000;
  font-family: "Helvetica", sans-serif !important;
  font-size: 11.5px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 15.57px;
}

.seller-description {
  font-family: "Helvetica", sans-serif !important;
  color: #000000;
  font-size: 14px !important;
  font-weight: 400;
}

.checked-label.seller-description {
  font-size: 11px !important;
}

.unchecked-label.seller-description {
  font-size: 11px !important;
}

.content-tooltip {
  font-family: Inter, Arial, sans-serif !important;
}

.rating-count {
  color: var(--white-color);
  font-size: 12px;
  font-weight: 400;
}

.seller-bordered {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 8px;
  margin: 10px 0px;
}

.sellers-field {
  font-size: 15px;
  padding: 9px 7px;
  color: #000000;
  background-color: var(--white-color);
  background-image: none;
  border: 1px solid var(--border-color);
}

.search-btn {
  color: var(--white-color);
  background-color: var(--link-color);
  padding: 9px 33px;
  border-radius: 6px;
  outline: none;
  border: none;
}

.seller-description a,
.desc-a {
  color: var(--link-color);
}

.inputs-search {
  height: 32px !important;
  border-radius: 8px !important;
  border: 1px solid #d5d7da !important;
  color: #717680 !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 14px !important;
  padding-left: 30px;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}

.inputs-search::placeholder {
  color: #717680 !important;
  font-weight: 400 !important;
}

.search-icons {
  position: absolute;
  left: 20px;
  top: 31%;
  z-index: 11;
}

.flex-one {
  flex: 1 !important;
}

.search-icon-second {
  position: absolute;
  left: 8px;
  z-index: 11;
  top: 6px;
}

.relative {
  position: relative;
}

.accordion-item {
  box-shadow: 0px 2px 8px 0px rgba(16, 24, 40, 0.05) !important;
  background: #fff !important;
}

.accordion-button {
  background-color: #f0f0f0 !important;
  border-radius: 6px 6px 0px 0px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 6.5px 8px !important;
  z-index: 1;
  color: var(--white-color) !important;
  border: 1px solid #d4d6d8 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 30px;
  font-family: "Inter", serif !important;
}

.descriptions-counts {
  color: #535862;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.accordion-item:first-of-type .accordion-button {
  background-color: #f0f0f0 !important;
  border: 1px solid #d4d6d8 !important;
  z-index: 1;
  color: #181d27 !important;
  font-weight: 600 !important;
  font-family: "Inter", serif !important;
}

.input-fields {
  padding: 13px 88px 13px 13px;
  border-radius: 4px;
  background-color: #f0f5f9;
  margin: 7px;
  border: none;
  font-size: 13px;
  width: 77%;
}

.offer-search {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}

.product-image {
  max-width: 100%;
  height: 100%;
}

.product-image img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}

.flex-button {
  background: #155eef !important;
  border: none;
  outline: none;
  border-radius: 4px !important;
  padding: 0px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-button img {
  max-width: 18px;
  width: 100%;
  height: 18px;
}

.span-one {
  width: 49px;
}

.seller-row {
  row-gap: 6px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.seller-heading {
  font-size: 24px;
  font-weight: 600;
  margin: 40px 0px;
}

.seller-blue {
  color: var(--link-color) !important;
}

.accordion-button:not(.collapsed) {
  color: #000000 !important;
}

.accordion-body {
  padding: 3.5px !important;
  border-radius: 0px !important;
}
.chart-padding .accordion-body {
  padding: 3.5px 0px 3.5px 0px !important;
  border-radius: 0px !important;
}
.ranks-offers {
  position: relative;
}

.ranks-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.ranks-loader-particular {
  width: 95%;
  height: 95%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.panel {
  margin-bottom: 0 !important;
  border: 1px solid #c9c9c9 !important;
  background: #fff !important;
  outline: none;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  text-align: center !important;
}

.panel-default > .panel-heading {
  padding: 1px 5px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #c9c9c9;
  background: #f0f0f0;
  min-height: 20px;
  padding-top: 3px;
  color: #717680 !important;
  text-align: center;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.panel-body {
  padding: 3.5px !important;
  min-height: 28px;
  font-size: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-collapse {
  border: 1px solid #e9eaeb !important;
  border-radius: 0px 0px 6px 6px !important;
}

.criteria-warning {
  border-color: #faebcc;
}

.warning-text {
  color: #8a6d3b;
  font-size: 12px;
}

.padding-content {
  padding: 8px 10px !important;
  gap: 10px !important;
  border-bottom: 1px solid #e2e2e2;
}

.form-group .input-cost {
  height: 25px !important;
}

.ranks-description {
  color: #535862 !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}

.input-group .money-input {
  text-align: center !important;
}

.money-input {
  border-radius: 0px 4px 4px 0px !important;
  border: 1px solid #e9eaeb !important;
  background: #fff !important;
  height: 32px !important;
  text-align: center !important;
}

.seller-offer-btn {
  height: 27px !important;
}

.widget-column {
  row-gap: 4px;
  padding: 2px 12px;
}

.criteria-danger-or-warning {
  border-radius: 0px 0px 4px 4px;
}

.qi-alert-not {
  color: white;
  width: 20px;
  height: 20px;
  padding: 0px;
  border-radius: 6px;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 19px;
  display: inline-block;
  margin-bottom: 0;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background-image: none;
}

.qi-alert-not-warning {
  background-color: #e8a217;
}

.qi-alert-not-danger {
  background-color: #a94442;
}

.text-warning {
  text-align: right;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.text-danger {
  color: #b42419 !important;
  text-align: right;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.me-icon {
  max-width: 25px;
  width: 100%;
}

.profit-text {
  color: #202020;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.total-text {
  color: #535862;
}

.profile-text {
  color: #047647;
  text-align: right;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.profile-text-danger {
  color: #a94442;
  text-align: right;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0px !important;
}

.text-success {
  color: #047647 !important;
  text-align: right;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.criteria-fail,
.criteria-danger {
  color: #a94442;
  background-color: #f2dede;
  text-align: center;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 500;
  border-radius: 0px 0px 3px 3px;
  line-height: 18px;
}

.criteria-info {
  background-color: #edf6fc;
  color: #036aa3;
  border-radius: 0px 0px 3px 3px;
  text-align: center;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.criteria-zero {
  background-color: #fcf8e3;
  color: #8a6d3b;
  border-radius: 0px 0px 3px 3px;
  text-align: center;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.info {
  color: #337ab7;
}

.even_stripe {
  background-color: #fff;
  border-radius: 0px 0px 3px 3px !important;
  text-align: center;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.input-group {
  width: 100%;
  display: flex;
  gap: 0;
}

.input-group-addon {
  padding: 0px 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  border-radius: 4px 0px 0px 4px !important;
  background: #eee;
  height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.yellow-star {
  color: #fda41d;
  font-size: 20px !important;
}

.rating-stars {
  font-size: 13px !important;
  color: #fdb022;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: #f9f9f9 !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: white !important;
  --bs-table-color-type: none;
  --bs-table-bg-type: none;
}

.calculator {
  font-size: 17px;
}

.calculator-body .table-striped {
  width: 100%;
  border-collapse: separate !important;
  border-spacing: 0px 8px;
}

.calculator-body .table-striped tr {
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  background: #f9f9f9;
}

.calculator-body .table-striped td:first-child {
  border-radius: 8px 0px 0px 8px;
}

.calculator-body .table-striped td:last-child {
  border-radius: 0px 8px 8px 0px;
}

.toggler {
  background: transparent;
  height: 20px;
  width: 60px;
  border-radius: 49px;
  position: relative;
}

.toggler-two {
  height: 22px;
  width: 95px;
  border-radius: 49px;
  position: relative;
}

.checkbox-desc {
  border: 1px solid #dee2e6 !important;
}

.toggler input[type="checkbox"],
.background {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
  z-index: 120;
  border-radius: 4px;
  background-color: transparent;
}

.toggler input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  top: 0.5%;
  background: #155eef;
  left: 0%;
  border-radius: 49px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
  z-index: 20;
}

.toggler-two input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  top: 0.5%;
  background: #155eef;
  left: 0%;
  border-radius: 49px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
  z-index: 20;
}

.toggler input:checked[type="checkbox"]::after {
  left: 69.5%;
}

.toggler-two input:checked[type="checkbox"]::after {
  left: 75px;
}

.field-ex {
  margin-top: -1px;
}

.toggler input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  width: 100%;
  inset: 0;
  border-radius: 49px;
  border: 1px solid #d4d6d8 !important;
}

.toggler input[type="checkbox"]:active,
.toggler input[type="checkbox"]:focus,
.toggler .background,
.toggler label,
.toggler {
  outline: none !important;
  border: none !important;
}

.toggler label {
  font-size: 13px;
  position: absolute;
  top: 23%;
  z-index: 100;
}

.toggler-two .checked-label {
  left: 6px !important;
  top: 4px !important;
  color: #39403c !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 500;
}

.toggler-two .unchecked-label {
  left: 28px !important;
  color: #39403c !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px !important;
  font-style: normal;
  font-weight: 500;
  top: 4.5px !important;
}

.toggler .checked-label {
  left: 8px;
  top: 2.5px;
}

.toggler .unchecked-label {
  left: 25px;
  color: var(--black-color) !important;
  top: 1px;
}

.toggler .background {
  width: 0;
  z-index: 99;
}

.toggler-three .checked-label {
  color: var(--black-color) !important;
}

.toggler-three .seller-description {
  font-size: 11px;
  font-weight: normal;
}

.toggler-three .unchecked-label {
  color: var(--black-color) !important;
}

.toggler-three .checked-background {
  background-color: #f5f5f5 !important;
  border-radius: 50px !important;
}

.toggler-three .unchecked-background {
  background-color: #f5f5f5 !important;
  border-radius: 50px !important;
}

.toggler .unchecked-background {
  background-color: #e7e7e7;
  right: 0;
  border-radius: 49px !important;
}

.offers-box {
  font-size: 15px;
  font-weight: bold;
}

.vlookup-box {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 2px 2px 2px 2px;
}

.source-text {
  border-right: 1px solid #ddd;
  padding-right: 10px;
}

.toggler-two .unchecked-background {
  background-color: #e7e7e7;
  border-radius: 49px !important;
  right: 0;
}

.toggler-four .checked-background {
  background-color: #e7e7e7 !important;
  border-radius: 49px !important;
}

.toggler-four .unchecked-background {
  background-color: #e7e7e7 !important;
  border-radius: 49px !important;
}

.toggler-four .checked-label {
  color: var(--black-color) !important;
}

.toggler-four .unchecked-label {
  color: var(--black-color) !important;
}

.toggler .checked-background {
  background-color: #e7e7e7;
  border-radius: 49px !important;
  left: 0;
}

.toggler-two .checked-background {
  background-color: #e7e7e7;
  left: 0;
  border-radius: 49px !important;
}

.toggler input[type="checkbox"]:not(:checked) ~ .unchecked-background,
.toggler input[type="checkbox"]:checked ~ .checked-background {
  -webkit-animation-name: check;
  animation-name: check;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.toggler input[type="checkbox"]:not(:checked) ~ .checked-background,
.toggler input[type="checkbox"]:checked ~ .unchecked-background {
  -webkit-animation-name: uncheck;
  animation-name: uncheck;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.toggler input[type="checkbox"]:not(:checked) ~ .checked-label,
.toggler input[type="checkbox"]:checked ~ .unchecked-label {
  visibility: hidden;
}

@-webkit-keyframes check {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes check {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@-webkit-keyframes uncheck {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

@keyframes uncheck {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.range {
  width: 100%;
}

.bad-hl {
  color: #991a00;
}

.collapse-button {
  border: none;
  outline: none;
  margin-left: 5px;
  background: transparent !important;
  padding: 0px;
}

.detail-items {
  padding: 0px 24px;
}

/* Styles for the range slider */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  max-width: 80px;
  height: 12px;
  border-radius: 5px;
  background: #f5f5f5;
  outline: none;
  border: 1px solid #d4d6d8;
  margin: 4px 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #155eef;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #155eef;
  cursor: pointer;
}

.tooltip {
  max-width: 330px !important;
  width: 100% !important;
}

.detail-heading {
  color: #181d27;
  margin: 0 !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.main-product-description {
  color: #535862 !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 18px;
}

.about-detailed {
  max-width: 800px !important;
  width: 100%;
}

.row-sub-caption {
  padding-left: 30px;
}

#tooltip-top {
  max-width: 200px !important;
  width: 100% !important;
  font-size: 11px !important;
}

#tooltip-bottom {
  max-width: 250px !important;
  width: 100% !important;
  font-size: 11px !important;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(24%, 2fr));
  grid-auto-rows: minmax(auto, max-content);
  gap: 9px;
  padding: 8px 5px 4px 5px;
}

.grid-item {
  gap: 16px !important;
}

.overview-text h1 {
  color: #000000;
  font-family: "Helvetica", sans-serif !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 8px;
}

.product-container,
.quick-info,
.profit-calculator,
.ranks-and-prices {
  max-width: 100%;
}

.keepa-selected-link {
  border-radius: 4px;
  background: #fbbc31 !important;
  border: none !important;
  font-size: 12px !important;
  outline: none;
  padding: 4px 7px !important;
  height: 28px !important;
  color: #2d2d2d !important;
  font-family: "Helvetica", sans-serif !important;
  font-weight: 600 !important;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-link {
  border-radius: 4px;
  background: #fbbc31 !important;
  border: none !important;
  font-size: 12px !important;
  outline: none;
  padding: 4px 7px !important;
  height: 28px !important;
  color: #2d2d2d !important;
  font-family: "Helvetica", sans-serif !important;
  font-weight: 600 !important;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.keepa-selected-link img {
  min-width: 18px !important;
  width: 100%;
  max-width: 18px;
}

.simple-link {
  font-size: 12px !important;
  border-radius: 4px;
  border: 1px solid #828282;
  outline: none;
  background-color: var(--white-color);
  padding: 4px 7px !important;
  height: 28px !important;
  color: #314d7f;
  font-family: "Helvetica", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.simple-link:hover {
  text-decoration: underline !important;
}

.good-hl,
.good-hl-icon {
  color: #008000;
  font-weight: normal !important;
}

.bb-amz,
.bb-0 {
  background-color: #ffeedc;
  border: 1px solid #ffab12;
  color: #8a6d3b;
}

.text-bubble {
  margin-left: 5px;
  font-weight: 400;
  font-size: 12px;
  min-width: 35px;
  padding: 3px 7px;
  height: 28px;
  border-radius: 0.25rem !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.down-error {
  color: #991a00;
}

.down-up {
  color: #008000;
}

.none-icon {
  display: none !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../Assests/accordion-up.svg") !important;
  margin-top: 2px !important;
  rotate: 180deg !important;
}

.accordion-button::after {
  background-image: url("../../Assests/arrow-accordion.svg") !important;
  --bs-accordion-btn-icon-width: 15px !important;
  margin-top: 2px !important;
  color: #333 !important;
}

td .btn-refresh {
  border-radius: 6px;
  background: #155eef !important;
  border: 1px solid #155eef !important;
  outline: none;
  padding: 5px 7px !important;
  height: 24px !important;
}

.btn-refresh {
  border-radius: 6px;
  background: #155eef !important;
  border: 1px solid #155eef !important;
  font-size: 12px !important;
  outline: none;
  padding: 5px 8px !important;
  height: 28px !important;
  color: #ffff !important;
  text-transform: capitalize !important;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 600 !important;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-wrap .btn-refresh {
  border-radius: 6px;
  background: #155eef !important;
  border: 1px solid #155eef !important;
  font-size: 12px !important;
  outline: none;
  padding: 5px 8px !important;
  height: 32px !important;
  color: #ffff !important;
  text-transform: capitalize !important;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 600 !important;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-buttons {
  padding: 0px 12px !important;
  gap: 12px;
}

.other-btns {
  padding: 0px 12px 10px 12px !important;
  margin-top: 10px;
  gap: 12px;
}

.sellerss-btn {
  border-radius: 8px;
  background: #155eef;
  padding: 6px 8px;
  color: #fff;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  height: 32px;
  display: flex;
  justify-content: center;
  gap: 5px;
  border: none;
  outline: none;
  align-items: center;
}

.offers-contentsss {
  padding: 9px 12px;
}
.variations-group .offers-contentsss {
  padding: 9px 4px 9px 12px !important;
  border-bottom: 1px solid #d8d8d8;
  margin-right: 8px;
}
.icons-btns {
  border-radius: 8px;
  background: #155eef;
  width: 34px;
  height: 32px;
  padding: 0 !important;
  border: none;
  outline: none;
}

.icons-btns img {
  max-width: 20px;
  width: 100%;
}

.flex-btns {
  border-radius: 4px;
  background: #fbbc31 !important;
  border: 1px solid transparent !important;
  font-size: 12px !important;
  outline: none;
  padding: 5px 7px !important;
  height: 30px !important;
  color: #2d2d2d !important;
  font-family: "Helvetica", sans-serif !important;
  font-weight: 600 !important;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-search-again {
  border-radius: 4px;
  background: #fbbc31 !important;
  border: none !important;
  font-size: 12px !important;
  outline: none;
  padding: 5px 7px !important;
  height: 30px !important;
  color: #2d2d2d !important;
  font-family: "Helvetica", sans-serif !important;
  font-weight: 600 !important;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 70px;
  width: 100%;
}

.appex-chart-high-chart .highcharts-container {
  height: 200px !important;
}

.appex-bordered {
  border-top: 1px solid #d4d6d8 !important;
}

.discount-btn-refresh {
  border-radius: 8px;
  border: 1px solid #155eef;
  outline: none;
  background-color: var(--white-color);
  padding: 5px 9px !important;
  height: 32px !important;
  color: #155eef;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 18px;
  transition: all 0.3s ease;
}

.discount-btn-refresh:hover {
  background: #155eef !important;
  border: 1px solid #155eef !important;
  color: white !important;
}
.discount-btn-refresh:hover .goArrow-icon {
  color: white;
}
.btn-refresh .goArrow-icon {
  color: white;
}
.analysis-padding {
  padding: 0 !important;
}

.dotted-border {
  border-bottom: 1px dotted #333;
}

.panel-col {
  padding: 0px 2px !important;
}

.offer-cart {
  padding-right: 5px;
  font-size: 17px;
  color: var(--white-color) !important;
}

.verticle-middle {
  vertical-align: middle;
}

.refreshed-time-function {
  font-size: 12px;
}

.table-bordered > thead > tr > th {
  color: #717680 !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  background-color: #f0f0f0 !important;
  text-transform: capitalize !important;
}

.main-columm {
  padding: 6px 20px !important;
}

.table-bordered > tbody > tr > th {
  border-right: 1px solid #ddd !important;
  text-align: center;
  vertical-align: middle;
  color: #000000;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.visible-table > tbody > tr > td {
  font-family: "Helvetica", sans-serif !important;
  vertical-align: middle;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.offers-table > thead > tr > th {
  color: #232323 !important;
  text-align: center !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  background-color: #dedede !important;
  padding: 8px 8px !important;
  line-height: 18px;
}

.offers-table > tbody > tr > td {
  text-align: center;
  color: #181d27 !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px;
}

.offers-table > tbody > tr > th {
  color: #181d27 !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px;
}

.table-bordered > tbody > tr > td {
  vertical-align: middle;
}

.plain-box {
  border-radius: 6px !important;
  border: 1px solid #e9eaeb;
  color: #333;
  text-align: center;
  height: 29px !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.14px;
  min-width: 67px !important;
}
.plain-box-offers {
  border-radius: 6px !important;
  border: 1px solid #d4d6d8;
  color: #333;
  text-align: center;
  height: 29px !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  min-width: 54px !important;
}
.horizontal-list li {
  margin-left: 0 !important;
}

.product-analysis {
  color: #535862 !important;
  text-align: center !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

.bb-fba,
.bb-10 {
  color: #fff;
  border-radius: 6px;
  border: 1px solid #155eef;
  background: #155eef;
  text-align: center;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.14px;
}
.FBA-10 {
  width: 40px;
  height: 26px;
  color: #414651;
  font-size: 12px !important;
  border-radius: 6px 0px 0px 0px;
  background-color: #e9fbef;
  border: 1px solid #87e8ad;
  display: flex;
  justify-content: center;
  align-items: center;
}
.amz-10 {
  width: 40px;
  height: 26px;
  color: #414651;
  font-size: 12px !important;
  border-radius: 6px 0px 0px 0px;
  background-color: #fff4d9;
  border: 1px solid #daa520 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.FBM-7 {
  width: 40px;
  height: 26px;
  border: 1px solid #155eef !important;
  background-color: #fff;
  color: #155eef;
  font-size: 12px !important;
  border-radius: 6px 0px 0px 0px;
}
.FBM-7-width {
  border: 1px solid #155eef !important;
  background-color: #fff;
  min-width: 46px;
  height: 26px;
  color: #155eef;
  font-size: 12px !important;
  border-radius: 0.25rem !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bb-10 .pseudolink {
  color: white !important;
  font-size: 12px !important;
}

.pseudolink {
  font-size: 12px !important;
}

.dotted-white {
  border-bottom: 1px dotted #fff;
}

.search-container {
  margin: 18px 0px 14px 0px;
}

.bb-fbm,
.bb-7,
.warning-box {
  color: #8a6d3b !important;
  background-color: #fcf8e3 !important;
  border: 1px solid #8a6d3b !important;
}

.loading-loader {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nat-note {
  border-radius: 6px;
  border: 1px solid #155eef;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.nat-note-left {
  flex-grow: 1;
  overflow: hidden;
  margin-right: 5px;
}

.textarea-note-text {
  resize: vertical;
  width: 100%;
  vertical-align: top;
  height: 68px;
  border-radius: 6px;
  border: 1px solid #155eef !important;
  font-size: 16px;
  padding: 4px 2px;
  overflow: auto;
  transition: border-color 0.3s ease;
}

.textarea-note-text:focus {
  border: 1px solid #155eef !important;
}

textarea:focus {
  border: 1px solid #155eef !important;
}

.icon-text {
  font-size: 15px;
}

.login-sc-msg-box {
  color: var(--fbm-color);
  background-color: var(--fbm-bg-color);
  border: 1px solid var(--fbm-color);
  padding: 5px;
  font-size: 12px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.warning-hl {
  color: #f0ad4e;
  font-weight: normal !important;
}

.vat-select {
  border-radius: 8px;
  border: 1px solid #155eef;
  background: #155eef;
  font-weight: 600;
  padding: 6px 7px;
  color: #fff;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  height: 32px;
}

.vat-select:focus {
  border: 1px solid #155eef;
  outline: none;
}

.vat-description {
  color: #181d27;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sample-name {
  padding: 0px;
  width: 100%;
  margin: 0px 4px;
  max-width: 30px;
}

.loading-anim {
  height: 170px;
  background: url("./../../Assests/loading.gif") 50% no-repeat;
}

.loading-buyBox {
  height: 30px;
  background: url("./../../Assests/loading.gif") 50% no-repeat;
}

.keepa-image {
  width: 100%;
  margin: 0px 0px;
  max-width: 100%;
  padding: 0px 0px;
  height: 100%;
  min-height: 170px;
  max-height: 170px;
  object-fit: fill;
  background-color: #f9f9f9 !important;
}
.product-details {
  padding-right: 5px !important;
}

.vertical-div-box {
  height: 700px;
}

.appex-chart {
  border-bottom: 1px solid #aaa;
  padding-bottom: 0 !important;
}

.warning-background {
  background-color: #f0ad4e;
  border: 1px solid #f0ad4e;
}

.styled-box {
  width: 22px;
  height: 20px;
  border-radius: 4px;
  color: var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  padding-top: 2px;
  margin-right: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.counter-borders {
  border-top: 1px solid #e9eaeb !important;
}

@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap");

#main {
  position: relative;
}

.cross-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  background-color: #155eef;
  border-radius: 6px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-cross-icon {
  color: #fff;
}

.slider-magnifier-btn {
  position: absolute;
  right: 0px;
  top: 4px;
}

.review-description {
  color: #333;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.asin-desc {
  color: #717680;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.asin-explaination {
  color: #181d27;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.asin-explaination .product-row {
  padding: 0px 0px;
  margin: 0px 0px !important;
  border-radius: 2px 2px 5px 5px !important;
}

.accordion-product {
  padding: 0px 0px !important;
}

.image-carousel {
  flex-grow: 1;
  padding: 10px 12px 10px 8px !important;
}

.market-description {
  color: #535862;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.btn-simple {
  padding: 0;
  border: none;
  outline: none;
}

.country-content-listing .btn-simple {
  padding: 4px 8px;
}

.country-content-listing .btnn.discount-btn-refresh {
  padding: 0px 8px !important;
  height: 24px !important;
  border-radius: 6px !important;
  border: 1px solid #155eef !important;
  background: #fff !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.offeres-out {
  border-bottom: 1px solid #e9eaeb !important;
}

#wrapper {
  height: 190px;
  display: flex;
  border-radius: 10px;
  border: 1px solid #aaa;
  background-color: var(--white-color);
  padding: 20px 0px;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  gap: 0px;
  z-index: 300 !important;
  max-width: 240px;
  top: 6px;
  left: 1px;
}

#wrapper i {
  font-size: 25px;
  pointer-events: none;
}

#image-container {
  height: 100%;
  width: 20vw;
  overflow: hidden;
  padding: 0px 0px;
}

#image-carousel {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 100px;
  position: relative;
}

#image-carousel img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 10px;
  position: absolute;
  transition: 1s;
  padding: 0px 0px;
}

#wrapper .active {
  background-color: transparent;
  border-radius: 6px;
  border: 1px solid #aaa;
  margin: 8px 7px;
  padding: 6px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#wrapper .active svg {
  color: #155eef;
}

#wrapper .active:hover {
  background-color: #e7e7e7;
}

button {
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
  border-radius: 10px;
  border-color: none;
  outline: none;
}

.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield;
}

.referral-fee-tooltip {
  font-size: 12px;
}

.cursor-copy {
  cursor: copy;
}

.criteria-success {
  background-color: #dff0d8;
  border-radius: 0px 0px 4px 4px;
  color: #047647;
  text-align: center;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.goArrow-icon {
  color: #155eef;
}
.goArrow-icon:hover {
  color: #ffffff;
}
.highcharts-credits,
.highcharts-title {
  display: none;
}

.highcharts-background {
  min-height: 200px !important;
  height: 200px;
  max-height: 200px;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  max-width: 800px;
  margin: 1em auto;
}

.hidden .highcharts-container {
  display: none !important;
}

#chart {
  overflow: hidden;
}

.highcharts-container {
  height: 350px !important;
}

#highcharts-kcqdpsi-6 {
  position: relative;
  overflow: hidden;
  width: 453px;
  height: 200px !important;
  text-align: left;
  line-height: normal;
  z-index: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
  touch-action: manipulation;
  outline: none;
}

.filter-btn {
  border-radius: 6px !important;
  height: 32px !important;
  background-color: #155eef !important;
  color: white !important;
}

.analysis-btn {
  border-radius: 6px !important;
  height: 32px !important;
  background-color: #155eef !important;
  color: white !important;
  border: none !important;
  outline: none !important;
}

.analysis-btn-disable {
  border-radius: 6px !important;
  height: 32px !important;
  font-size: 12px !important;
  background-color: rgb(103, 150, 245) !important;
  color: white !important;
  cursor: not-allowed !important;
  padding: 2px 12px !important;
  outline: none;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  border: 1px solid rgb(103, 150, 245) !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.label-items {
  display: block;
  padding: 3px 20px;
}

.label-items input {
  height: 15px;
  width: 15px;
}

.label-items label {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.428571429;
}

.transparent-btn {
  color: #000000;
  background-color: #fff;
  border: 1px solid #ccc !important;
  outline: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px !important;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  cursor: pointer;
}

.transparent-btn:hover {
  color: #000000;
  background-color: #e6e6e6;
  border: 1px solid #adadad;
}

.seller-btn {
  outline: none;
  height: 32px;
  padding: 2px 8px !important;
}

.analaysis-img {
  width: 20px;
}

.flex-wrap .btn-streched {
  height: 28px !important;
}

.seller-btn-discounts {
  font-size: 12px !important;
  line-height: normal;
  border-radius: 4px;
  border: 1px solid #828282;
  outline: none;
  background-color: var(--white-color);
  padding: 4px 7px !important;
  height: 28px !important;
  color: #314d7f;
  font-family: "Helvetica", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-filter .modal.show .modal-dialog {
  overflow: hidden;
}

.modal-filter-overview .modal.show .modal-dialog {
  overflow: hidden;
}

.modal-filter .modal-dialog {
  max-width: 650px;
  margin: 30px auto;
  width: 100%;
  border-radius: 0 !important;
  font-family: "Helvetica", sans-serif !important;
}
.modal-filter-marketplace .modal-dialog {
  max-width: 740px !important;
  margin: 30px auto;
  width: 100%;
  border-radius: 0 !important;
  font-family: "Helvetica", sans-serif !important;
}
.confirmation-modal .modal-dialog {
  max-width: 480px !important;
  margin: 30px auto;
  width: 100%;
  border-radius: 0 !important;
  font-family: "Helvetica", sans-serif !important;
}

.modal-filter-overview .modal-dialog {
  max-width: 99%;
  margin: 12px auto;
  width: 100%;
  border-radius: 0 !important;
  font-family: "Helvetica", sans-serif !important;
}

.modal-filter,
.modal-filter-overview {
  z-index: 1111111111 !important;
}

.modal-filter .modal-body {
  padding: 5px 10px !important;
}

.modal-filter-overview .modal-body {
  padding: 5px 10px !important;
}

.modal-filter .btn-close {
  max-width: 15px !important;
  padding: 0 !important;
}

.modal-filter .modal-header {
  justify-content: flex-start;
  align-items: center;
  background-color: #4b1b97;
  padding: 12px 10px !important;
  border-radius: 0 !important;
}

.modal-filter-overview .modal-header {
  justify-content: flex-start;
  align-items: center;
  background-color: #4b1b97;
  padding: 10px 10px !important;
  border-radius: 0 !important;
}

.modal-filter .modal-header .modal-title {
  color: var(--white-color);
  font-size: 17px !important;
  font-weight: 500 !important;
}

.modal-filter-overview .modal-header .modal-title {
  color: var(--white-color);
  font-size: 15px !important;
  font-weight: normal !important;
}

.modal-backdrop {
  z-index: 1120 !important;
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px !important;
  padding: 4px 8px !important;
  height: 35px !important;
}

.modal {
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

.fixed-table-container {
  position: relative;
  clear: both;
}

.fixed-table-container .table {
  width: 100%;
  margin-bottom: 0 !important;
}

.fixed-table-container .table thead th {
  vertical-align: bottom;
  padding: 0;
  margin: 0;
  font-weight: 400px !important;
  text-align: center;
  border-bottom: 2px solid #ddd;
}

.fixed-table-container .table td {
  vertical-align: middle;
  box-sizing: border-box;
  padding: 7px 5px;
  font-weight: 400 !important;
  font-size: 13px !important;
  border-top: 1px solid #ddd;
}

.modal-filter .modal-footer {
  padding: 5px 10px;
}

.modal-filter .modal-content {
  border-radius: 0 !important;
}

.th-inner {
  font-weight: 500;
  font-size: 12px !important;
}

.form-select-select {
  -webkit-appearance: none !important;
  appearance: none;
  background-image: url("../../Assests/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 12px;
  z-index: 9;
}

.disabled-button {
  opacity: 0.7;
  cursor: not-allowed;
}

.alert {
  text-align: center;
  margin-bottom: 10px !important;
  padding: 10px !important;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-danger {
  color: #a94442 !important;
  background-color: #f2dede !important;
  border-color: #ebccd1 !important;
}

.fba-btn {
  border: 1px solid #155eef;
  background: #155eef;
  color: #fff;
  text-align: center;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.14px;
}

@media (max-width: 1600px) {
  .break-even {
    font-size: 12px !important;
  }
}

@media (max-width: 1600px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}
@media (max-width: 1480px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(3, 1fr));
    grid-template-columns: repeat(auto-fill, minmax(3, 1fr));
    grid-template-rows: auto;
    grid-column-gap: 9px;
    grid-row-gap: 9px;
  }

  .grid-one {
    grid-area: 1 / 1 / 2 / 2;
  }
  .grid-two {
    grid-area: 1 / 2 / 3 / 3;
  }
  .grid-three {
    grid-area: 1 / 3 / 2 / 4;
  }
  .grid-four {
    grid-area: 2 / 1 / 3 / 2;
  }
}
@media (max-width: 1280px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-template-rows: auto;
    grid-column-gap: 9px;
    grid-row-gap: 9px;
  }

  .grid-one {
    grid-area: 1 / 1 / 2 / 2;
  }
  .grid-two {
    grid-area: 1 / 2 / 3 / 3;
  }
  .grid-three {
    grid-area: 1 / 3 / 2 / 4;
  }
  .grid-four {
    grid-area: 2 / 1 / 3 / 2;
  }
}

/* 125% pr screen settings  */
@media (max-width: 1080px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    grid-auto-rows: auto;
    grid-column-gap: 9px;
    grid-row-gap: 9px;
  }
  .grid-one {
    grid-area: 1 / 1 / 2 / 2;
  }
  .grid-two {
    grid-area: 1 / 2 / 2 / 3;
  }
  .grid-three {
    grid-area: 2 / 1 / 3 / 2;
  }
  .grid-four {
    grid-area: 2 / 2 / 3 / 3;
  }
}

/* 150% screen settings */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));
    grid-template-rows: auto;
  }
  .grid-one {
    grid-area: unset;
  }
  .grid-two {
    grid-area: unset;
  }
  .grid-three {
    grid-area: unset;
  }
  .grid-four {
    grid-area: unset;
  }
}
/* .grid-container {
  grid-template-columns: repeat(auto-fill, minmax(80%, 1fr));
  grid-template-rows: auto;
}
.grid-one {
  grid-area: unset;
}
.grid-two {
  grid-area: unset;
}
.grid-three {
  grid-area: unset;
}
.grid-four {
  grid-area: unset;
} */

@media (max-width: 400px) {
  .grid-container {
    /* grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); */
    grid-template-columns: repeat(auto-fill, minmax(90%, 1fr));
    grid-template-rows: auto;
  }
  .grid-one {
    grid-area: unset;
  }
  .grid-two {
    grid-area: unset;
  }
  .grid-three {
    grid-area: unset;
  }
  .grid-four {
    grid-area: unset;
  }
}

@media (max-width: 992px) {
  .seller-search {
    max-width: 100%;
  }

  #dropdown-basic-button div span {
    font-size: 12px !important;
  }
}

.offers-content .offer-content-simple thead tr:first-child th:first-child {
  background-color: none !important;
  border: none !important;
}

.offers-content .offer-content-simple thead tr {
  border: none !important;
}

/* Alerts Table */
.table > :not(caption) > * > * {
  padding: 0.25rem 0.5rem !important;
  border-bottom: none !important;
}

.ranks-body {
  padding: 0 !important;
}

.simple-alert-table tbody tr {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.simple-alert-table .tbody tr td {
  padding: 10px;
  border: 1px solid #ccc;
}

.enough-description {
  color: #181d27 !important;
  text-align: right !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.btn-blue {
  color: #fff !important;
  text-align: center !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px;
}

.simple-alert-table .seller-description {
  color: #39403c !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal;
}

.table .seller-description-text {
  font-family: "Helvetica", sans-serif !important;
  color: #000000;
  font-size: 14px !important;
  font-weight: 600 !important;
  text-decoration: line-through;
}

/* Ranks table  */
.ranks-table .simple-link {
  border-color: #314d7f !important;
}

.boxer-btn {
  background-color: #f0f0f0 !important;
  box-shadow: 0px 2px 8px 0px rgba(16, 24, 40, 0.05) !important;
  height: 27.09px;
  border: 1px solid #d4d6d8 !important;
  max-width: 30px;
  width: 100%;
  min-width: 30px;
  border-radius: 6px !important;
  text-align: center;
  color: #181d27 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica", sans-serif !important;
}

.boxering-btn {
  background-color: #4b1b97 !important;
  border: 1px solid #4b1b97 !important;
  width: 100%;
  min-width: 150px !important;
  border-radius: 8px;
  font-family: Outfit, sans-serif !important;
  text-align: center;
  padding: 15px 0px !important;
  color: white;
  min-height: 44px !important;
  display: flex;
  font-size: 16px !important;
  justify-content: center;
  align-items: center;
}

.product-container {
  position: relative !important;
}

.product-details-button {
  position: absolute;
  top: 2.5px;
}

.modal-body-brand {
  width: 100%;
}

.modal-body-brand .accordion-header {
  max-width: 120px;
  margin-left: auto;
  font-family: "Helvetica", sans-serif !important;
}

.modal-body-brand .accordion-item {
  outline: none;
  border: none !important;
}

.class-type .offers-table {
  margin-bottom: 0 !important;
}

.alert-tooltips .tooltip-inner {
  max-width: 400px !important;
}

.table-custom tr:first-child {
  border-top: none;
}

.table-custom tr:last-child {
  border-bottom: none;
}

.table-custom tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.table-custom tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.profited-table tr:first-child {
  border-top: none !important;
}
.profited-table tr:last-child {
  border-bottom: none;
}

.offers-table tr:first-child {
  border-top: none !important;
}

.roi-table .offers-table > tbody > tr > td {
  color: #535862 !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12.5px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal;
  padding: 10px 5px !important;
}

.roi-table .offers-table > thead > tr > th {
  color: #717680 !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 18px;
  background-color: #fafafa !important;
}

.roi-table .offers-table > tbody > tr > th {
  font-size: 12.5px !important;
}

.input-group .boxx-money-control {
  border-radius: 0px 4px 4px 0px !important;
}

.input-group .not-money-input {
  border-radius: 4px 0px 0px 4px !important;
}
.detail-product-heading {
  padding-right: 3px !important;
}

.buy-Box-span{
  width: 62px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  color: #DAA520;
  border: 1px solid #DAA520;
  border-radius: 8px;
}