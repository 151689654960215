@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");

.header {
  height: 80px;
  background-color: #fff;
  z-index: 11111111;
  padding: 0px 20px 0px 10px !important;
  position: fixed;
  width: 100% !important;
  top: 0;
  border-bottom: 0.25px solid #b0b0b0;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.header.full-width {
  width: 100% !important;
}

.sidebar::-webkit-scrollbar {
  width: 4px;
  height: 50%;
}

.sidebar::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #4b1b97 0.03%, #180931 99.97%);
  border-radius: 5px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(90deg, #4b1b97 0.03%, #180931 99.97%);
}

.sidebar.collapsed {
  left: -250px;
  transition: 0.5s;
}
.main-menu.collapsed {
  left: -250px;
  transition: 0.5s !important;
}

.header.collapsed {
  max-width: 100%;
  width: 100% !important;
  left: 0 !important;
  transition: 0.5s;
}

.main-layout.sidebar-open {
  margin-left: 250px;
  transition: 0.5s;
}

.main-layout.sidebar-closed {
  margin-left: 0px;
}

.sidebar-list {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subcategories {
  max-height: 0 !important;
  overflow: hidden !important;
  padding-top: 4px !important;
  padding-left: 0px !important;
  opacity: 0 !important;
  transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out !important;
}

.subcategories.show {
  max-height: 300px !important;
  opacity: 1 !important;
}

.subcategories p {
  color: #cacaca;
}

.logout-btn {
  color: #eb5757;
}

.brand-title {
  color: var(--basetheme);
  font-size: 2rem;
  background-color: var(--basetheme);
  display: none;
  font-weight: bolder;
}

.toggle {
  z-index: 11111;
  position: fixed;
  color: white;
  font-size: 1.3rem;
  cursor: pointer;
}

.toggle-menu {
  top: 20px;
  left: 20px;
}

.toggle-arrow {
  position: static !important;
  font-size: 22px !important;
  padding-left: 0px;
  padding-right: 2px;
}

.toggle-sidebar {
  background-color: #0c0417;
  width: 30px;
  position: absolute;
  left: -9px;
  height: 30px;
  border-radius: 50%;
  border: 4px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 24px;
}

.list-name {
  list-style: none;
}

.router-name .active {
  color: #000;
  background-color: #f0f0f0;
  transition: all 0.3s ease !important;
  width: 249px;
}

.route-link {
  cursor: pointer;
  text-decoration: none;
  border-radius: 12px;
  display: flex;
  font-size: 16px;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  transition: all 0.3s ease !important;
}

.router-menu {
  gap: 14px !important;
}

.route-link:hover {
  padding: 14px 14px;
  border-radius: 12px;
  background: linear-gradient(90deg, #4b1b97 0.03%, #180931 99.97%);
  box-shadow: 1px 1px 12px 0px rgba(100, 56, 245, 0.15);
}

.logout-section button {
  gap: 14px !important;
  width: 100% !important;
  border: none !important;
  outline: none !important;
}

.logout-section button span {
  color: #fff;
  font-family: Outfit, sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
}

.layout-fixed.collapsed {
  max-width: 0;
  width: 0;
}

.input-search {
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  background: #fafafa !important;
  max-width: 731px;
  width: 100%;
  padding-right: 12px;
  height: 50px;
  display: flex;
  align-items: center;
  min-width: 731px;
}

:where(.css-dev-only-do-not-override-6j9yrn).ant-dropdown .ant-dropdown-menu {
  margin-top: 26px !important;
  z-index: 11111 !important;
}

.input-search-field {
  padding-left: 42px;
  flex: 1;
  border: none;
  padding-right: 10px;
  outline: none;
  color: #aca8a8;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: #fafafa;
}

.input-search-field:focus {
  outline: none !important;
  box-shadow: none !important;
}

.input-search-field::placeholder {
  color: #a4a4a4;
}

.search-icon {
  font-size: 27px;
  color: #adadad;
}

.sub-drop {
  padding: 6px 8px 6px 8px;
  border-radius: 4px;
  background: #155eef1a;
  font-family: Outfit, sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 102px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #155eef !important;
}

.ant-dropdown-menu {
  top: 16px !important;
}

.anticon {
  padding-left: 10px !important;
}

.anticon svg {
  padding-top: 2px !important;
}

.user-info h2 {
  color: #36435c;
  font-family: Outfit, sans-serif !important;
  font-size: 15px;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}

.user-info p {
  color: #4b1b97;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
}

.layout-fixed {
  height: 100vh;
  width: 0;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  align-items: center;
}

.layout-fixed::-webkit-scrollbar {
  width: 3px;
}

.layout-fixed::-webkit-scrollbar-thumb {
  background-color: var(--bordercolor);
}

.layout-fixed::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.layout-fixed.open {
  width: 250px;
}

.layout-fixed .closebtn {
  align-self: flex-start;
  font-size: 36px;
  color: #818181;
  cursor: pointer;
}

.outlet-layout {
  margin: 5.9rem 0px 0rem 0px;
  transition: all 0.5s ease;
  flex: 1;
}

.outlet-layout:has(.overview-layout) {
  margin: 4.9rem 0px 1rem 0px;
  padding: 0px 10px;
}

.outlet-layout.shifted {
  margin-left: 43px;
}
.site-logo {
  width: 220px;
  height: 30px;
}

.left-arrow-sidebar {
  padding-left: 2px;
  padding-right: 0px;
}

.icon-items {
  color: #fff;
  font-family: Outfit, sans-serif !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.icon-button {
  display: flex;
  text-align: center;
  border-radius: 12px;
  border: 0.5px solid #c8c8d1;
  background: rgba(255, 255, 255, 0);
  align-items: center;
  height: 38px;
  width: 38px;
  justify-content: center;
}

.icon-button .css-i4bv87-MuiSvgIcon-root {
  font-size: 24px !important;
  height: 23px !important;
  width: 40px !important;
  cursor: pointer !important;
  padding-top: 2px !important;
}

.icon-button button {
  padding: 0 !important;
}

.circle {
  position: absolute;
  background-color: red;
  color: white;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  top: 2px;
  left: 20px;
}

.logout-option {
  position: absolute;
  right: 0;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.profile-text {
  font-family: "albert light", sans-serif;
  font-size: 10px;
  margin: 0px 5px;
}

.contact-query {
  cursor: pointer;
  position: fixed;
  bottom: 5px;
  left: 20px;
  z-index: 1;
}

.logout-loading {
  position: absolute;
  right: 50%;
  top: 50%;
}

.logout-popup {
  position: fixed;
  display: block;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1111111;
  cursor: pointer;
  text-align: center;
}

.input-search-desktop {
  display: flex;
}

.input-search-mobile {
  display: none;
}
.open-close-icon-display {
  display: none;
}
.search-icon-main {
  position: absolute;
  left: 10px;
}
.profile-dropdown {
  gap: 10px;
}
.profile-img {
  width: 45px;
  height: 44px;
  border-radius: 50%;
}
@media screen and (max-width: 1050px) {
  .header-dropdown {
    display: display;
  }

  .header-navbar {
    display: none !important;
  }
}

@media screen and (max-width: 750px) {
  .input-search {
    max-width: 170px;
    min-width: 170px;
    width: 100%;
    height: 40px;
  }

  .site-logo {
    width: 100px;
    height: 30px;
  }
  .profile-img {
    display: none !important;
    width: 36px;
    height: 35px;
  }
  .profile-icon {
    display: none !important;
  }
  .profile-dropdown {
    gap: 5px !important;
  }
  .search-icon-main {
    position: relative;
    right: -158px;
  }

  .input-search-field {
    margin-left: -19px !important;
    max-width: 100%;
    width: 100%;
  }

  .sub-drop {
    display: none !important;
  }

  .input-search-desktop {
    display: dis;
  }

  .input-search-mobile {
    display: flex;
  }
}

@media screen and (max-width: 426px) {
  .layout-fixed.open {
    width: 270px;
    background-color: white;
  }
}

@media screen and (max-width: 720px) {
  .outlet-layout.shifted {
    margin-left: 0px;
  }

  .header {
    width: 100% !important;
    padding: 0px 7px 0px 7px !important;
  }
  .open-close-icon-display {
    display: block;
  }
}

.footer {
  border-top: 0.25px solid #b0b0b0;
  color: #4a4a4a;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 20px;
  padding-left: 100px;
  flex-shrink: 0;
}

.footer p span {
  color: #4b1b97;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.footer p span a {
  text-decoration: underline !important;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.header-navbar {
  min-width: 130px;
}

.middle-nav {
  font-size: 16px;
  font-weight: 300;
  color: black;
  text-decoration: none;
}

.middle-nav.active {
  color: #4b1b97 !important;
}
.header-dropdown .dropdown-toggle {
  border: none !important;
  padding: none !important;
}
.profile-btn {
  padding: none !important;
}
.dropdown-toggle::after {
  display: none;
}

.dropdown-menu {
  box-shadow: rgba(103, 132, 162, 0.08) 0px 2px 3px,
    rgba(103, 132, 162, 0.16) 0px 1px 8px !important;
  width: 260px !important;
}

.dropdown-divider {
  border: 0.5px solid #ebebeb !important;
  margin: 0 !important;
}

.dropdown-menu .dropdown-item {
  color: #747474 !important;
  padding: 10px 18px;
  display: flex;
  align-items: center;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #747474 !important;
  background-color: none !important;
}

.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-none) !important;
}

.user-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.userName-h2 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 800;
  text-align: center;
  color: rgb(19, 25, 31);
  font-family: Inter, Arial, sans-serif !important;
}
.userEmail-hp {
  font-size: 16px;
  line-height: 24px;
  color: rgb(72, 94, 117);
  font-family: Inter, Arial, sans-serif !important;
}
hr {
  margin: 0.8rem 0 !important;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

.scroll-sidebar {
  max-height: calc(-160px + 100vh);
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-sidebar::-webkit-scrollbar {
  width: 10px;
  scrollbar-gutter: stable;
  max-width: 10px;
}
.scroll-sidebar::-webkit-scrollbar-track {
  background: #eaf1ff;
  border-radius: 10px;
}
.scroll-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: gray;
  opacity: 0.1;
}
.scroll-sidebar::-webkit-scrollbar:horizontal {
  max-height: 5px;
}
.scroll-sidebar::-webkit-scrollbar-thumb:horizontal {
  border-radius: 10px;
  background-color: gray;
  height: auto;
}
.main-menu:hover,
nav.main-menu.expanded {
  width: 250px !important;
  overflow: visible;
}

.main-menu:hover .product-analyzer-span,
nav.main-menu.expanded .product-analyzer-span {
  display: block;
  color: #181d27;
  font-family: Outfit, sans-serif !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.product-analyzer-span {
  display: none;
}
.hr-border {
  display: none;
}

.main-menu:hover .hr-border,
nav.main-menu.expanded .hr-border {
  display: block;
  border-top: 0.5px solid rgb(208, 208, 208);
  margin: 10px -7px 10px 20px;
  width: 210px;
}
.main-menu {
  background: #fff;
  border-right: 1px solid #e5e5e5;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 50px !important;
  overflow: hidden;
  -webkit-transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 999 !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  transition: 0.5s !important;
}

.main-menu > ul {
  margin: 95px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 248px;
}

.main-menu li > a {
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
  color: #181d27;
  font-family: Outfit, sans-serif !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  height: 38px;
  display: flex;
  align-items: center;
}
.logout-button {
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
  color: #181d27;
  font-family: Outfit, sans-serif !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  height: 38px;
  display: flex;
  align-items: center;
}
.scroll-sidebar .fa {
  height: 17px !important;
  width: 50px !important;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 181px;
  font-family: "Titillium Web", sans-serif;
}

.main-menu > ul.logout {
  position: absolute;
  left: 0;
  bottom: 0;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow-x: visible; /* Set overflow-x explicitly */
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}
.css-o4ds0q-MuiPopper-root-MuiTooltip-popper {
  z-index: 11111111 !important;
}
.main-menu li:hover > a,
nav.main-menu li.active > a,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a,
.logout-button:hover {
  color: #000;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  height: 38px;
  width: 248px;
  font-size: 16px !important;
}
@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);

@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300);
.area {
  float: center;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
  padding-left: 60px;
}

.fa-2x {
  font-size: 2em;
}
.fa {
  position: relative;
  display: table-cell;
  width: 50px;
  height: 19px;
  text-align: center;
  vertical-align: middle;
}
.fa:active {
  display: flex;
  align-items: center;
  height: 18px;
}
.profile-icon {
  display: flex;
  flex-direction: column;
}
.profile-icon h2 {
  font-family: Inter, Arial, sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #4a4a4a;
  margin-bottom: 1px !important;
}
.profile-icon p {
  font-family: Inter, Arial, sans-serif !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #757575;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 600px) {
  .main-menu {
    width: 250px !important;
  }
  .main-menu .product-analyzer-span,
  nav.main-menu.expanded .product-analyzer-span {
    display: block;
    color: #e7e7e7;
    font-family: Outfit, sans-serif !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    padding-left: 20px;
    padding-bottom: 5px;
  }
  .main-menu .hr-border,
  nav.main-menu.expanded .hr-border {
    display: block;
    border-top: 0.5px solid rgb(208, 208, 208);
    margin: 4px -7px 10px 20px;
    width: 210px;
  }
  .header-dropdown .dropdown-toggle {
    border: 1px solid #dfdfdf !important;
  }
}

.ag-tooltip .ag-tooltip-inner {
  white-space: pre-line !important; 
}