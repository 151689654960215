@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --basetheme: #00c853;
  --basethemelight: #00a042;
  --bordercolor: rgb(219, 224, 235);
  --fontcolor: rgb(79, 79, 79);
  --bodycolor: white;
  --tablecolor: white;
  --textcolor: white;
  --activecolor: #00a042;
  --bodycolor: white;
}

body {
  background-color: var(--bodycolor) !important;
}

.app-container:has(.subscription-container) .outlet-layout {
  margin: 4.9rem 0px 0rem 0px;
}

.app-container:has(.subscription-container) {
  background-color: #f1f4fb !important;
}

:where(.css-dev-only-do-not-override-6j9yrn).ant-modal .ant-modal-content {
  padding: 0;
  border-radius: 0 !important;
  margin: 0 !important;
}

:where(.css-6j9yrn).ant-modal .ant-modal-content {
  padding: 0px 0px 20px 0px !important;
}

:where(.css-6j9yrn).ant-modal .ant-modal-footer {
  margin: 10px 15px !important;
}

.css-wigwbp {
  padding: 12px 28px !important;
  font-size: 15px !important;
  font-family: "Albert", sans-serif !important;
  font-weight: 500 !important;
}

.css-orq8zk {
  color: black !important;
}

.error-message-textarea {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.plan-page {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  animation: fadeIn 1000ms ease;
  -webkit-animation: fadeIn 1000ms ease;
}

.plan-page h1 {
  font-size: 50px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 700;
}

.plan-page h2 {
  font-size: 35px;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  line-height: 1;
  font-weight: 700;
}

.plan-page .dot {
  color: #44929c;
}

.slider-sub-heading {
  color: white;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0%;
  text-align: center;
  padding-top: 20px;
}

.PricingSlider_sliderImage__o-SaT {
  object-fit: contain !important;
  width: 100% !important;
  max-width: 904px !important;
}

.pro-cell {
  position: relative;
}

.pro-badge {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  width: 36;
  height: 24;
  border-radius: 4px;
  padding-top: 6px;
  padding-right: 8px;
  padding-bottom: 6px;
  padding-left: 8px;
  gap: 12px;
  background-color: #155eef1a;
  color: #155eef;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  letter-spacing: 0%;
}
.pro-badge-scanner {
  background-color: #155eef1a;
  color: #155eef;
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  letter-spacing: 0%;
  padding-top: 6px;
  padding-right: 8px;
  padding-bottom: 6px;
  padding-left: 8px;
  border-radius: 4px;
}
.pro-title {
  font-family: "Product Sans", sans-serif;
  font-size: 60px;
  font-weight: 700;
  line-height: 72.78px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.pricing-slider {
  background-color: #155eef !important;
  background-image: url(./Assests/dotPattern.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 !important;
  overflow: hidden !important;
}

.pro-text {
  max-width: 500px !important;
  margin: 0 auto !important;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 20px 0px;
}

.PricingSlider_arrow__qHC1d {
  background-color: transparent !important;
}

.PricingSlider_arrow__qHC1d svg {
  font-size: 40px !important;
}

.PricingTab_productBtn__K39kz {
  border: none !important;
  background-color: #e4e4e4 !important;
  width: 560px;
  height: 68px;
  top: 127px;
  left: 154px;
  gap: 0px;
  border-radius: 4px 0px 0px 0px;
  opacity: 0px;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
}

.PricingTab_activeBtn__P-AEX {
  border: 1px solid transparent !important;
  background-color: #155eef !important;
  width: 560px;
  height: 68px;
  top: 127px;
  left: 726px;
  gap: 0px;
  border-radius: 4px 0px 0px 0px;
  opacity: 0px;
  font-family: Inter, Arial, sans-serif !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
}
@media (max-width: 1200px) {
  .PricingSlider_sliderImage__o-SaT {
    max-width: 704px !important;
  }
}

@media (max-width: 768px) {
  .PricingSlider_sliderImage__o-SaT {
    object-fit: contain !important;
    width: 100% !important;
    max-width: 300px !important;
  }
  .upload-text {
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 19.36px !important;
    letter-spacing: 0%;
  }
}
@media (max-width: 400px) {
  .PricingSlider_sliderImage__o-SaT {
    object-fit: contain !important;
    width: 100% !important;
    max-width: 270px !important;
  }
  .slider-sub-heading {
    color: white;
    font-family: Inter, Arial, sans-serif !important;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    letter-spacing: 0%;
    text-align: center;
    padding-top: 20px;
  }
}
.upload-text {
  font-family: Inter, Arial, sans-serif !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: 0%;
}

.PricingSlider_arrow__vy4hW {
  background: transparent !important;
  color: white !important;
  font-size: 20px !important;
}


.ag-tooltip .ag-tooltip-inner {
  white-space: pre-line;
}